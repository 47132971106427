<template>
  <div class="hotLine_warp">
    <div class="hotLine_content">
      <a-spin :spinning="loading">
        <div class="hotLine_top">
          <div class="title">
            <a-icon class="icon" type="phone" style="font-size: 25px;" />
            <span style="margin-left: 5px; font-size: 25px;font-weight: bolder;">软控IT运维中心</span>
          </div>
          <div class="close" @click="$router.back(-1)">
            <a-icon class="icon" type="left" />
            返回
          </div>
        </div>

        <div style="border:1px solid #cfcfcf; border-radius:5px; ">
          <!-- 功能区 -->
          <a-row :gutter="36" style="padding: 20px">
            <!-- 我提出的问题 -->
            <a-col :span="6">
              <a-row type="flex" justify="center" style="border-radius: 3px; background: #ffffff;">
                <a-col :span="8" type="flex" justify="center" align="center"
                       style="height: 4.5vw; line-height: 4.5vw; background-color: #cb4c3d ; border-radius: 3px ;padding: 10px;">
                  <a-icon type="bug" theme="filled" style=" color: #ffffff; font-size: 40px; " />
                </a-col>
                <a-col :span="12" style=" height: 4.5vw; display:table;">
                  <div style="padding-left: 10px; text-align: left; display:table-cell; vertical-align:middle; ">
                    <div>我提出的问题</div>
                    <div style="font-size:30px; font-family: electronicFont; font-weight: bolder;">
                      {{ userQuestionsCountInfo.myQuestionNum }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>

            <!-- 未解决问题 -->
            <a-col :span="6">
              <a-row type="flex" justify="center" style="border-radius: 3px; background: #ffffff;">
                <a-col :span="8" type="flex" justify="center" align="center"
                       style="height: 4.5vw; line-height: 4.5vw; background-color: #5b5b5b ; border-radius: 3px ;padding: 10px;">
                  <a-icon type="bulb" theme="filled" style=" color: #ffffff; font-size: 40px; " />
                </a-col>
                <a-col :span="12" style=" height: 4.5vw; display:table;">
                  <div style="padding-left: 10px; text-align: left; display:table-cell; vertical-align:middle; ">
                    <div>未解决问题</div>
                    <div style="font-size:30px; font-family: electronicFont; font-weight: bolder;">
                      {{ userQuestionsCountInfo.unresolvedMyQuestionNum }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>

            <!-- 待处理问题 -->
            <a-col :span="6">
              <a-row type="flex" justify="center" style="border-radius: 3px; background: #ffffff;">
                <a-col :span="8" type="flex" justify="center" align="center"
                       style="height: 4.5vw; line-height: 4.5vw; background-color: #3365d5 ; border-radius: 3px ;padding: 10px;">
                  <a-icon type="book" theme="filled" style=" color: #ffffff; font-size: 40px; " />
                </a-col>
                <a-col :span="12" style=" height: 4.5vw; display:table;">
                  <div style="padding-left: 10px; text-align: left; display:table-cell; vertical-align:middle; ">
                    <div>待处理问题</div>
                    <div style="font-size:30px; font-family: electronicFont; font-weight: bolder;">
                      {{ userQuestionsCountInfo.needAuditQuestionNum }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>

            <!-- 投诉建议 -->
            <a-col :span="6">
              <a-row type="flex" justify="center" style="border-radius: 3px; background: #ffffff;">
                <a-col :span="8" type="flex" justify="center" align="center"
                       style="height: 4.5vw; line-height: 4.5vw; background-color: #be0e2e ; border-radius: 3px ;padding: 10px;">
                  <a-icon type="warning" theme="filled" style=" color: #ffffff; font-size: 40px; " />
                </a-col>
                <a-col :span="12" style=" height: 4.5vw; display:table;">
                  <div style="padding-left: 10px; text-align: left; display:table-cell; vertical-align:middle; ">
                    <div>投诉建议</div>
                    <div style="font-size:30px; font-family: electronicFont; font-weight: bolder;">
                      {{ suggestionCount }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>

        <!-- 待审批问题 -->
        <div style="border: 1px solid #c0c0c0; background-color: #efefef; padding: 5px 20px 5px 20px; margin-top: 10px">
          <a-row type="flex" align="middle">
            <a-col :span="24">
              <a-icon type="table" style="font-size: 20px; color: #be0e2e; " />
              <span style="margin-left: 5px; font-size: 20px;font-weight: bolder;">待审批问题</span>
            </a-col>
          </a-row>
        </div>
        <a-table
          :columns="needAuditQuestionDataInfoColumns"
          :row-key="record => record.id"
          :data-source="needAuditQuestionDataInfo"
          :pagination="needAuditQuestionPagination"
          :loading="needAuditQuestionLoading"
          @change="needAuditQuestionHandleTableChange"
        >
          <a-icon slot="icon" type="tag" />
          <a slot="requirementsProblem" slot-scope="requirementsProblem,record"
             target="_blank" :href="record.workFlowUrl">
            {{ requirementsProblem }}</a>
        </a-table>

        <div style="margin-top: 10px">
          <a-row :gutter="8">
            <!-- 我的提问 -->
            <a-col :span="12">
              <div style="border: 1px solid #c0c0c0; background-color: #efefef; padding: 5px 20px 5px 20px">
                <a-row type="flex" align="middle">
                  <a-col :span="20">
                    <a-icon type="global" style="font-size: 20px; color: #2dad9a " />
                    <span style="margin-left: 5px; font-size: 20px;font-weight: bolder;">我的提问</span>
                  </a-col>
                  <a-col :span="4">
                    <div style="text-decoration:underline; cursor:pointer;" @click="moreInfo(1)">
                      <a-icon type="double-right" />
                      <span style="font-size: 15px;">新建提问</span>
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div style="border: 1px solid #c0c0c0; padding: 0 20px 0 20px">
                <a-tabs default-active-key="1">
                  <a-tab-pane key="1" tab="未解决">
                    <a-table
                      :columns="unresolvedMyQuestionDataInfoColumns"
                      :row-key="record => record.id"
                      :data-source="unresolvedMyQuestionDataInfo"
                      :pagination="unresolvedMyQuestionPagination"
                      :loading="unresolvedMyQuestionLoading"
                      @change="unresolvedMyQuestionHandleTableChange"
                    >
                      <a-icon slot="icon" type="tag" />
                      <a slot="requirementsProblem" slot-scope="requirementsProblem,record"
                         target="_blank" :href="record.workFlowUrl">
                        {{ requirementsProblem }}</a>
                    </a-table>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="已解决" force-render>
                    <a-table
                      :columns="resolvedMyQuestionDataInfoColumns"
                      :row-key="record => record.id"
                      :data-source="resolvedMyQuestionDataInfo"
                      :pagination="resolvedMyQuestionPagination"
                      :loading="resolvedMyQuestionLoading"
                      @change="resolvedMyQuestionHandleTableChange"
                    >
                      <a-icon slot="icon" type="tag" />
                      <a slot="requirementsProblem" slot-scope="requirementsProblem,record"
                         target="_blank" :href="record.workFlowUrl">
                        {{ requirementsProblem }}</a>
                    </a-table>
                  </a-tab-pane>
                </a-tabs>
              </div>
            </a-col>

            <!-- 我的投诉建议 -->
            <a-col :span="12">
              <div style="border: 1px solid #c0c0c0; background-color: #efefef; padding: 5px 20px 5px 20px">
                <a-row type="flex" align="middle">
                  <a-col :span="18">
                    <a-icon type="table" style="font-size: 20px; color: #be0e2e; " />
                    <span style="margin-left: 5px; font-size: 20px;font-weight: bolder;">我的投诉建议</span>
                  </a-col>
                  <a-col :span="6">
                    <div style="text-decoration:underline; cursor:pointer;" @click="showNewQusModal(false)">
                      <a-icon type="double-right" />
                      <span style="font-size: 15px;">新建投诉建议</span>
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div style="border: 1px solid #c0c0c0; padding: 0 20px 0 20px">
                <a-tabs default-active-key="1">
                  <a-tab-pane key="1" tab="未处理">
                    <a-table
                      :columns="suggestionDataInfoColumns"
                      :row-key="record => record.id"
                      :data-source="unProcessSuggestionDataInfo"
                      :pagination="unProcessSuggestionPagination"
                      :loading="unProcessSuggestionLoading"
                      @change="unProcessSuggestionHandleTableChange"
                    >
                      <a-icon slot="icon" type="tag" />
                      <a slot="suggestionDesc" slot-scope="suggestionDesc,record"
                         @click="showNewQusModal(true,record.id)">
                        {{ suggestionDesc }}</a>
                    </a-table>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="已处理" force-render>
                    <a-table
                      :columns="suggestionDataInfoColumns"
                      :row-key="record => record.id"
                      :data-source="processSuggestionDataInfo"
                      :pagination="processSuggestionPagination"
                      :loading="processSuggestionLoading"
                      @change="processSuggestionHandleTableChange"
                    >
                      <div slot="icon" slot-scope="icon">
                        <a-icon type="tag" />
                        {{ icon }}
                      </div>
                      <a slot="suggestionDesc" slot-scope="suggestionDesc,record"
                         @click="showNewQusModal(true,record.id)">
                        {{ suggestionDesc }}
                      </a>
                    </a-table>
                  </a-tab-pane>
                </a-tabs>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-spin>
    </div>


    <a-modal
      ref="newQusModal"
      :title="newQusModalTitle"
      :visible="newQusModalVisible"
      :confirm-loading="newQusConfirmLoading"
      :maskClosable="false"
      centered
      :ok-button-props="{ props: { disabled : newQusModalSubmitDisabledFlag } }"
      @ok="newQusHandleOk"
      @cancel="newQusHandleCancel"
    >
      <div>
        <a-form-model ref="newQusForm"
                      :model="newQusForm"
                      :label-col="{ span: 4 }"
                      :wrapper-col="{ span: 20 }"
                      :rules="newQusFormRules">
          <a-form-model-item prop="systemType" label="投诉对象" required>
            <a-input placeholder="投诉对象" :disabled="newQusModalSubmitDisabledFlag" v-model="newQusForm.systemType" />
          </a-form-model-item>
          <a-form-model-item prop="suggestionDesc" label="问题描述" required>
            <a-textarea :disabled="newQusModalSubmitDisabledFlag"
                        placeholder="请输入问题描述"
                        :auto-size="{ minRows: 6, maxRows: 6 }"
                        v-model="newQusForm.suggestionDesc"
            />
          </a-form-model-item>
          <a-form-model-item label="相关截图">
            <a-upload
              :action="url.fileUploadUrl"
              list-type="picture-card"
              :file-list="fileList"
              @preview="handlePreview"
              @change="handleChange"
              :disabled="newQusModalSubmitDisabledFlag"
            >
              <div v-if="fileList.length < 4">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="previewHandleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>


          <a-form-model-item v-if="newQusModalSubmitDisabledFlag" prop="processStatus" label="处理状态" required>
            <a-select default-value="0" v-model="newQusForm.processStatus" disabled>
              <a-select-option value="0">未处理</a-select-option>
              <a-select-option value="1">已处理</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="newQusModalSubmitDisabledFlag" prop="processDesc" label="处理反馈">
            <a-textarea :auto-size="{ minRows: 6, maxRows: 6 }" v-model="newQusForm.processDesc" disabled />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>

  </div>
</template>

<script>
import { getAction, httpAction } from '@/api/manage'
import { MESNAC_SYSTEM_OPS_URL } from '@/views/user/modules/config'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default {
  data() {
    return {
      url: MESNAC_SYSTEM_OPS_URL,

      userInfo: Vue.ls.get(USER_INFO),

      visible: false,
      loading: false,

      // 新增问题建议不可用标志（新增 false，查看 true）
      newQusModalSubmitDisabledFlag: false,
      newQusModalTitle: '',
      newQusModalVisible: false,
      newQusConfirmLoading: false,

      // 问题表单
      newQusForm: {},
      // 问题表单规则
      newQusFormRules: {
        systemType: [
          { required: true, message: '请选择系统', trigger: 'change' }
        ],
        suggestionDesc: [
          { required: true, message: '请填写问题描述', trigger: 'blur' }
        ]
      },
      // 系统信息
      systemInfos: [],

      // 文件上传&预览
      previewVisible: false,
      previewImage: '',
      fileList: [],

      /////////////////////////////////////////////
      // 投诉建议数量
      suggestionCount: 0,

      // 用户的流程数量信息
      userQuestionsCountInfo: {
        myQuestionNum: 0,
        unresolvedMyQuestionNum: 0,
        needAuditQuestionNum: 0
      },
      // 待审批流程
      // 未处理
      needAuditQuestionDataInfoColumns: [
        {
          title: '问题描述',
          dataIndex: 'requirementsProblem',
          scopedSlots: { customRender: 'requirementsProblem' },
          ellipsis: true
        },
        {
          title: '所属系统',
          dataIndex: 'systemName',
          scopedSlots: { customRender: 'systemName' },
          ellipsis: true
        },
        {
          title: '当前节点',
          dataIndex: 'activityName',
          scopedSlots: { customRender: 'activityName' },
          ellipsis: true
        },
        {
          title: '当前处理人',
          dataIndex: 'currentProcessors',
          scopedSlots: { customRender: 'currentProcessors' },
          ellipsis: true
        },
        {
          title: '到达日期',
          dataIndex: 'currentProcessStartTime'
        }
      ],
      needAuditQuestionDataInfo: [],
      needAuditQuestionPagination: {
        pageSize: 5
      },
      needAuditQuestionLoading: false,

      /////////////////////////////////////////////
      // 我的投诉建议
      // 未处理
      unresolvedMyQuestionDataInfoColumns: [
        {
          title: '',
          dataIndex: 'icon',
          width: '5%',
          scopedSlots: { customRender: 'icon' }
        },
        {
          title: '问题描述',
          dataIndex: 'requirementsProblem',
          scopedSlots: { customRender: 'requirementsProblem' },
          ellipsis: true
        },
        {
          title: '所属系统',
          dataIndex: 'systemName',
          scopedSlots: { customRender: 'systemName' },
          ellipsis: true
        },
        {
          title: '当前节点',
          dataIndex: 'activityName',
          scopedSlots: { customRender: 'activityName' },
          ellipsis: true
        },
        {
          title: '当前处理人',
          dataIndex: 'currentProcessors',
          scopedSlots: { customRender: 'currentProcessors' },
          ellipsis: true
        }
      ],
      unresolvedMyQuestionDataInfo: [],
      unresolvedMyQuestionPagination: {
        pageSize: 5
      },
      unresolvedMyQuestionLoading: false,

      // 已处理
      resolvedMyQuestionDataInfoColumns: [
        {
          title: '',
          dataIndex: 'icon',
          width: '5%',
          scopedSlots: { customRender: 'icon' }
        },
        {
          title: '问题描述',
          dataIndex: 'requirementsProblem',
          scopedSlots: { customRender: 'requirementsProblem' },
          ellipsis: true
        },
        {
          title: '所属系统',
          dataIndex: 'systemName',
          scopedSlots: { customRender: 'systemName' },
          ellipsis: true
        }
      ],
      resolvedMyQuestionDataInfo: [],
      resolvedMyQuestionPagination: {
        pageSize: 5
      },
      resolvedMyQuestionLoading: false,
      /////////////////////////////////////////////
      // 我的投诉建议
      suggestionDataInfoColumns: [
        {
          title: '',
          dataIndex: 'icon',
          width: '5%',
          scopedSlots: { customRender: 'icon' }
        },
        {
          title: '问题描述',
          dataIndex: 'suggestionDesc',
          width: '70%',
          scopedSlots: { customRender: 'suggestionDesc' },
          ellipsis: true
        },
        {
          title: '投诉对象',
          dataIndex: 'systemType',
          ellipsis: true
        }
      ],

      // 未处理
      unProcessSuggestionDataInfo: [],
      unProcessSuggestionPagination: {
        pageSize: 5
      },
      unProcessSuggestionLoading: false,

      // 已处理
      processSuggestionDataInfo: [],
      processSuggestionPagination: {
        pageSize: 5
      },
      processSuggestionLoading: false
      /////////////////////////////////////////////

    }
  },
  created() {
    this.loadNoticeInfo()
  },
  methods: {
    moreInfo(type) {
      this.$message.success('coming soon...')
    },

    ////////////////////////////
    // 新建问题
    // 新建问题Model弹出
    showNewQusModal(viewFlag, suggestionId) {
      this.newQusModalTitle = viewFlag ? '投诉建议' : '新建投诉建议'
      this.newQusModalVisible = true
      this.newQusModalSubmitDisabledFlag = false
      this.fileList = []
      this.newQusForm = {}

      if (viewFlag) {
        // 不能提交
        this.newQusModalSubmitDisabledFlag = true
        getAction(this.url.querySuggestionById, { id: suggestionId }).then(res => {
          if (res.success) {
            this.newQusForm = res.result
            let attachmentFile = this.newQusForm.attachmentFile
            if (attachmentFile !== null && attachmentFile !== undefined && attachmentFile !== '') {
              // 设置已上传文件
              //let fileNames = attachmentFile.substring(attachmentFile.lastIndexOf(",")+1).split(',')
              let fileNames = attachmentFile.split(',')
              fileNames.forEach((fileName, index) => {
                if (fileName != '') {
                  this.fileList.push({
                    uid: '-' + index,
                    name: fileName,
                    status: 'done',
                    url: this.url.fileViewUrl + fileName
                  })
                }
              })
            }

          }
        })

      }
    },
    // 提交问题 & 投诉建议
    newQusHandleOk(e) {
      this.$refs.newQusForm.validate(valid => {
        if (valid) {
          let that = this
          this.$confirm({
            title: '确认',
            content: '提交投诉建议?',
            onOk: function() {
              console.log('newQusHandleOk:', e)
              that.newQusconfirmLoading = true
              console.log('newQusForm', that.newQusForm)
              console.log('fileList', that.fileList)
              let attachmentFile = ''
              that.fileList.forEach(fileInfo => {
                if (fileInfo.response !== null && fileInfo.response.success) {
                  attachmentFile += fileInfo.response.message + ','
                } else {
                  attachmentFile += fileInfo.filename + ','
                }
              })
              // 附件信息
              that.newQusForm.attachmentFile = attachmentFile
              // 录入人信息
              that.newQusForm.createName = that.userInfo.realname
              that.newQusForm.createBy = that.userInfo.username
              that.newQusForm.sysOrgCode = that.userInfo.orgCode

              httpAction(that.url.addSuggestion, that.newQusForm, 'post').then(res => {
                if (res.success) {
                  that.$message.success('提交成功')
                  that.newQusModalVisible = false
                } else {
                  that.$message.warning('提交错误[' + res.message + ']')
                }
              })
            }
          })

        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    newQusHandleCancel(e) {
      console.log('newQusHandleCancel:', e)
      console.log('Clicked cancel button')
      this.newQusModalVisible = false
    },

    //////////////////////////////////////////////////
    // 上传图片相关方法
    previewHandleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange(info) {
      let successFlagInfo = true
      if (info.file.status === 'done') {
        if (info.file.response.success) {
          this.$message.success(`${info.file.name} 文件上传成功`)
        } else {
          this.$message.error(`${info.file.name} 文件上传失败`)
          successFlagInfo = false
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 文件上传失败`)
        successFlagInfo = false
      }

      this.fileList = info.fileList
      if (!successFlagInfo) {
        this.fileList.forEach((item, index, arr) => {
          if (item.uid === info.file.uid) {
            this.fileList.splice(index, 1)
          }
        })
      }
    },
    //////////////////////////////////////////////////

    //////////////////////////////////////////////////
    // 载入信息
    loadNoticeInfo() {
      // 0、获取投诉建议数量
      getAction(this.url.getSuggestionCount, { createBy: this.userInfo.username }).then(res => {
        if (res.success) {
          this.suggestionCount = res.result
        } else {
          this.$message.warn('获取投诉建议数量异常：' + res.message)
        }
      })

      // 0、获取字典类
      getAction(this.url.getSoSystemTypeDictItems).then(res => {
        if (res.success) {
          this.systemInfos = res.result

          // 1、获取流程数量
          getAction(this.url.getQuestionsCountInfoByUser, {
            userName: this.userInfo.username
          }).then(res => {
            if (res.success) {
              this.userQuestionsCountInfo = res.result
              console.log('getQuestionsCountInfoByUser:', this.userQuestionsCountInfo)
            } else {
              this.$message.warn('获取流程数量异常：' + res.message)
            }
          })

          // 2、分页获取问题流程 - 未处理
          this.needAuditQuestionFetch()

          // 3、分页获取问题流程 - 未处理
          this.unresolvedMyQuestionFetch()
          // 4、分页获取问题流程 - 已处理
          this.resolvedMyQuestionFetch()

          // 5、分页获取投诉建议 - 未处理
          this.unProcessSuggestionFetch()
          // 6、分页获取投诉建议 - 已处理
          this.processSuggestionFetch()

        } else {
          this.$message.warn('获取系统信息异常：' + res.message)
        }
      })
    },

    // 待审批-问题
    needAuditQuestionHandleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.needAuditQuestionPagination }
      pager.current = pagination.current
      this.needAuditQuestionPagination = pager
      this.needAuditQuestionFetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      })
    },
    needAuditQuestionFetch(params = {}) {
      this.needAuditQuestionLoading = true

      getAction(this.url.queryUserNeedAuditQuestionsPage, {
        userName: this.userInfo.username,
        ...params
      }).then(res => {
        if (res.success) {
          const pagination = { ...this.needAuditQuestionPagination }
          pagination.total = res.result.total

          this.needAuditQuestionLoading = false
          this.needAuditQuestionDataInfo = res.result.records
          this.needAuditQuestionPagination = pagination

          console.log('needAuditQuestionFetch:', this.needAuditQuestionDataInfo)
        } else {
          this.$message.warn('获取需要审批问题流程异常：' + res.message)
        }
      })
    },

    // 我的提问-未解决
    unresolvedMyQuestionHandleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.unResolvedMyQuestionPagination }
      pager.current = pagination.current
      this.unResolvedMyQuestionPagination = pager
      this.unresolvedMyQuestionFetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      })
    },
    unresolvedMyQuestionFetch(params = {}) {
      this.unresolvedMyQuestionLoading = true

      getAction(this.url.queryUserSubmitQuestionsPage, {
        userName: this.userInfo.username,
        isClosed: '否',
        ...params
      }).then(res => {
        if (res.success) {
          const pagination = { ...this.unResolvedMyQuestionPagination }
          pagination.total = res.result.total

          this.unresolvedMyQuestionLoading = false
          this.unresolvedMyQuestionDataInfo = res.result.records
          this.unresolvedMyQuestionPagination = pagination

          console.log('unresolvedMyQuestionFetch:', this.unresolvedMyQuestionDataInfo)

        } else {
          this.$message.warn('获取未完成问题流程信息异常：' + res.message)
        }
      })
    },

    // 我的提问-已解决
    resolvedMyQuestionHandleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.resolvedMyQuestionPagination }
      pager.current = pagination.current
      this.resolvedMyQuestionPagination = pager
      this.resolvedMyQuestionFetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      })
    },
    resolvedMyQuestionFetch(params = {}) {
      this.resolvedMyQuestionLoading = true

      getAction(this.url.queryUserSubmitQuestionsPage, {
        userName: this.userInfo.username,
        isClosed: '是',
        ...params
      }).then(res => {
        if (res.success) {
          const pagination = { ...this.resolvedMyQuestionPagination }
          pagination.total = res.result.total

          this.resolvedMyQuestionLoading = false
          this.resolvedMyQuestionDataInfo = res.result.records
          this.resolvedMyQuestionPagination = pagination

          console.log('resolvedMyQuestionFetch:', this.resolvedMyQuestionDataInfo)

        } else {
          this.$message.warn('获取已完成问题流程信息异常：' + res.message)
        }
      })
    },

    // 我的投诉建议-未处理
    unProcessSuggestionHandleTableChange(pagination, filters, sorter) {
        console.log("pagination")
            console.log(pagination)
      const pager = { ...this.unProcessSuggestionPagination }
      pager.current = pagination.current
      this.unProcessSuggestionPagination = pager
      this.unProcessSuggestionFetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      })
    },
    unProcessSuggestionFetch(params = {}) {
      this.unProcessSuggestionLoading = true

      getAction(this.url.querySuggestionList, {
        createBy: this.userInfo.username,
        processStatus: '0',
        ...params
      }).then(res => {
        if (res.success) {
          const pagination = { ...this.unProcessSuggestionPagination }
          pagination.total = res.result.total

          this.unProcessSuggestionLoading = false
          this.unProcessSuggestionDataInfo = res.result.records
          this.unProcessSuggestionPagination = pagination

          // console.log('unProcessSuggestionFetch ', this.unProcessSuggestionDataInfo)
        } else {
          this.$message.warn('获取未处理投诉建议异常：' + res.message)
        }
      })
    },

    // 我的投诉建议-已处理
    processSuggestionHandleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.processSuggestionPagination }
      pager.current = pagination.current
      this.processSuggestionPagination = pager
      this.processSuggestionFetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      })
    },
    processSuggestionFetch(params = {}) {
      this.processSuggestionLoading = true

      getAction(this.url.querySuggestionList, {
        createBy: this.userInfo.username,
        processStatus: '1',
        ...params
      }).then(res => {
        if (res.success) {
          const pagination = { ...this.processSuggestionPagination }
          pagination.total = res.result.total

          this.processSuggestionLoading = false
          this.processSuggestionDataInfo = res.result.records
          this.processSuggestionPagination = pagination

          // console.log('unProcessSuggestionFetch ', this.unProcessSuggestionDataInfo)
        } else {
          this.$message.warn('获取已处理投诉建议异常：' + res.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.height-80 {
  height: 80px;
}

.info_bg {
  // background: url('~@/assets/img/service_bg.png') no-repeat center;
  padding: 40px 20px 40px 20px;
}

.hotLine_warp {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
  // background: url('~@/assets/img/home_bg.png') no-repeat top center / cover;
  background: #f6f6f6;

  .hotLine_content {
    min-height: 800px;
    width: 1559px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;

    .hotLine_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .title {
        font-size: 22px;
        color: rgba(16, 16, 16, 100);
        font-family: SourceHanSansSC-bold;
      }

      .close {
        cursor: pointer;

        .icon {
          font-weight: 600;
          font-size: 22px;
        }
      }
    }
  }
}

///deep/.ant-table-thead{
//  display: none;
//}
</style>

